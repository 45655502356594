import React, { useState, useEffect, useContext } from 'react';
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Layout from '../../components/Layout';
import Pagination from '../../components/app_components/cPagination';
import Table from '../../components/app_components/cTable';
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../../appserv/GlobalContext';
import { DialogBox } from '../../components/app_components/cModal';
import LoadingBar from 'react-top-loading-bar';


export default function Orders() {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const { brand, batch } = useParams();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [category, setCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isBtnDisable, setBtnDisable] = useState(false);
    const itemsPerPage = 10;

    const { userInit } = useContext(GlobalContext);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    useEffect(() => {
        fetchOrders()
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
        setCurrentPage(1);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setCurrentPage(1);
    };


    const fetchOrders = async () => {
        const response = await fetch(`/api/app/brand/${brand}/${batch}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userInit.token}`
            }
        });
        const data = await response.json();
        if (data.message) {
            navigate(`/order-management/${brand}`);
        }
        else {
            const totalOrders = data.batchOrders.length;
            setTotalItems(totalOrders)
            setData(data.batchOrders);
        }

    }
    const fetchOrder = async (id) => {
        const response = await fetch(`/api/tools/sc/order/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userInit.token}`
            }
        });
        return (await response.json())
    }

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        fetchOrders(page);
    };

    const handleView = async (id) => {
        setProgress(25);
        setLoading(true);

        try {
            const response = await fetch(`/api/app/brand/${brand}/${batch}/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userInit.token}`
                },
            });
            const data = await response.json();

            let scOrderDetail = await fetchOrder(id);

            if (data.message === "No Orders Found") {
                setProgress(100);
                setLoading(false);
                return;
            }

            if (data.orderDetail) {
                const shippingData = [
                    {
                        source: 'Import',
                        customerName: `${data.orderDetail[0].customerName}`,
                        address1: data.orderDetail[0].address.address1,
                        address2: data.orderDetail[0].address.address2,
                        address3: data.orderDetail[0].address.address3,
                        city: data.orderDetail[0].address.city,
                        state: data.orderDetail[0].address.state,
                        zip: data.orderDetail[0].address.zip,
                        country: data.orderDetail[0].address.country,
                    },
                    {
                        source: 'Seller Central',
                        customerName: scOrderDetail[0].shipmentDetails[`${id}`].address.name,
                        address1: scOrderDetail[0].shipmentDetails[`${id}`].address.line1,
                        address2: scOrderDetail[0].shipmentDetails[`${id}`].address.line2,
                        address3: scOrderDetail[0].shipmentDetails[`${id}`].address.line3,
                        city: scOrderDetail[0].shipmentDetails[`${id}`].address.city,
                        state: scOrderDetail[0].shipmentDetails[`${id}`].address.stateOrRegion,
                        zip: scOrderDetail[0].shipmentDetails[`${id}`].address.postalCode,
                        country: scOrderDetail[0].shipmentDetails[`${id}`].address.countryCode,
                    }
                ];

                const isEqual = (obj1, obj2) => {
                    for (let key in obj1) {
                        if (key !== 'source' && obj1[key] !== obj2[key]) {
                            return false;
                        }
                    }
                    return true;
                };

                const validationError = isEqual(shippingData[0], shippingData[1]) ? 'Matched' : 'Mismatch';

                shippingData.forEach(item => item.validationError = validationError);

                const productData = [
                    {
                        source: 'B2B',
                        sku: data.orderDetail[0].products.sku || 'n/a',
                        quantity: data.orderDetail[0].products.quantity || 'n/a',
                        color: data.orderDetail[0].products.color || 'n/a',
                        stock: data.orderDetail[0].products.stock || '-',
                    },
                    {
                        source: 'Seller Central',
                        sku: scOrderDetail[0].orderItems[0].SellerSKU || 'n/a',
                        quantity: scOrderDetail[0].orderItems[0].QuantityOrdered || 'n/a',
                        color: scOrderDetail[0].orderItems[0].color || 'n/a',
                        stock: scOrderDetail[0].orderItems[0].stock || 'n/a',
                    }
                ];

                const shippingHeader = [
                    { displayName: 'Source', key: 'source' },
                    { displayName: 'Customer Name', key: 'customerName' },
                    { displayName: 'Address 1', key: 'address1' },
                    { displayName: 'Address 2', key: 'address2' },
                    { displayName: 'Address 3', key: 'address3' },
                    { displayName: 'City', key: 'city' },
                    { displayName: 'State', key: 'state' },
                    { displayName: 'Zip', key: 'zip' },
                    { displayName: 'Country', key: 'country' },
                    { displayName: 'Remarks', key: 'validationError' }
                ];

                const productHeader = [
                    { displayName: 'Source', key: 'source' },
                    { displayName: 'SKU', key: 'sku' },
                    { displayName: 'Quantity', key: 'quantity' },
                    { displayName: 'Color', key: 'color' },
                    { displayName: 'Stock', key: 'stock' },
                ];

                setIsModalOpen(true);
                setModalContent(
                    <div>
                        <div className="w-full">
                            <h1 className='font-bold text-lg'>Order ID: {id}</h1>
                            <h1>Order Status: <span className={`text-[#fff] ${data.orderDetail[0].status === 4 ? "bg-green-300" : "bg-yellow-500"} p-0.5 rounded-md px-2`}>{data.orderDetail[0].status === 4 ? "Shipped" : "Unshipped"}</span></h1>
                        </div>
                        <Table
                            data={shippingData}
                            unique={'orderID'}
                            tHeaders={shippingHeader}
                            title={`Shipping Details`}
                        />
                        <hr className="border-t border-gray-300 mt-2 mb-2 w-full" />
                        <Table
                            data={productData}
                            unique={'orderID'}
                            tHeaders={productHeader}
                            title={`Product Data`}
                        />
                        <hr className="border-t border-gray-300 mt-2 mb-2 w-full" />
                    </div>
                );
                setProgress(100);
                setLoading(false);
            }

        } catch (error) {
            setProgress(100);
            setLoading(false);
        }
    };


    const orderConfirmation = (id) => {
        setIsModalOpen(true);
        setModalContent(
            <div>
                <div className="w-full">
                    <h1 className='font-bold text-md'>Are you sure you want to place this order?</h1>
                    <h1>Order ID: {id}</h1>
                    <hr className="border-t border-gray-300 w-full mt-5" />
                    <div className='w-full  p-2 flex flex-row-reverse items-end mt-3'>
                        <button className="p-0.5 text-md bg-transparent rounded-md px-3 border border-solid border-red-300 hover:bg-red-300 text-red-300 hover:text-white" onClick={() => setIsModalOpen(false)}>Cancel</button>
                        <button className="p-0.5 text-md bg-red-300 mr-3 rounded-md px-3 hover:bg-red-400 text-white" onClick={() => handleOrder(id)}>Confirm</button>
                    </div>
                </div>

            </div>
        );
        setBtnDisable(false);
    }

    const handleOrder = async (id) => {
        setProgress(25);
        setLoading(true);
        setIsModalOpen(false);
        setBtnDisable(true);
        setLoading(false);
        const currentBatch = data.find((batchid) => batchid.orderID=== id);
        const stocksValidation = currentBatch ? currentBatch.products.stock : null;
        const quantity = currentBatch ? currentBatch.products.quantity : null;
        try {
            if(stocksValidation > quantity){
                const reqBody = {
                    brandID: `${brand}`,
                    orderID: `${id}`,
                    batchID: `${batch}`
                };
    
                const response = await fetch(`/api/app/brand/${brand}/${batch}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${userInit.token}`
                    },
                    body: JSON.stringify(reqBody)
                });
    
                const data = await response.json();
    
                if (data.message && typeof data.message === 'string') {
                    setIsModalOpen(true);
                    setModalContent(
                        <div className="w-full flex flex-col justify-center items-center text-lg p-2">
                            <h1 className="text-sm">Order ID: {id}</h1>
                            <h1 className="font-bold">{data.message}</h1>
                        </div>
                    );
                    setProgress(100);
                    setLoading(false);
                    fetchOrders();
                    setBtnDisable(false);
                } else if (data.message && data.message.data) {
                    setIsModalOpen(true);
                    setModalContent(
                        <div className="w-full flex flex-col justify-center items-center text-lg ">
                            <h1>Order ID: {id}</h1>
                            <h1 className="ml-1 text-[#A020F0] font-semibold">Ref#: {data.message.data[0].refNumber}</h1>
                            <h1>Status: {data.message.data[0].state}</h1>
                            <h1>Net total: {data.message.data[0].netPrice}</h1>
                        </div>
                    );
                    setProgress(100);
                    setLoading(false);
                    fetchOrders();
                    setBtnDisable(false);
                }
            }
            else{
                setIsModalOpen(true);
                setModalContent(
                    <div>
                        <div className="w-full">
                            <h1 className='font-bold text-md'>Stock Validation</h1>
                            <h1>Order {id} is currently out of stock from B2B</h1>
                            <h1>Quantity: {quantity}</h1>
                            <h1>Available Stock: {stocksValidation}</h1>
                            <hr className="border-t border-gray-300 w-full mt-5" />
                            <div className='w-full  p-2 flex flex-row-reverse items-end mt-3'>
                                <button className="p-0.5 text-md bg-transparent rounded-md px-3 border border-solid border-red-300 hover:bg-red-300 text-red-300 hover:text-white" onClick={() => setIsModalOpen(false)}>Cancel</button>
                            </div>
                        </div>
        
                    </div>
                );
                setProgress(100);
                setLoading(false);
                setBtnDisable(false);
            }


        } catch (error) {
            setProgress(100);
            setLoading(false);
            setIsModalOpen(true);
            setBtnDisable(false);
            setModalContent(
                <div>
                    <h1>Error: {error}</h1>
                </div>
            );
        }
    };

    const tblHeaders = [
        { displayName: 'Remarks', key: '', data: '<span>Good</span>' },
        { displayName: 'Order ID', key: 'orderID' },
        { displayName: 'PO Number', key: 'poNumber' },
        { displayName: 'Ref. Number', key: 'refNumber' },
        { displayName: 'Customer', key: 'customerName' },
        { displayName: 'Address', key: 'address.address1' },
        { displayName: 'City', key: 'address.city' },
        { displayName: 'state', key: 'address.state' },
        { displayName: 'Shipping Date', key: 'shippingDate' },
        { displayName: 'Status', key: 'status' },

    ];


    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <div>
            <Layout>
                <LoadingBar progress={progress} color="#D55440" onLoaderFinished={() => setProgress(0)} height={3} />
                <DialogBox isOpen={isModalOpen} closeModal={closeModal} contentElements={modalContent} />
                <Table
                    data={data}
                    unique={'orderID'}
                    tHeaders={tblHeaders}
                    onView={handleView}
                    onOrder={orderConfirmation}
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchChange}
                    filterStatus={filterStatus}
                    handleFilterChange={handleFilterChange}
                    category={category}
                    handleCategoryChange={handleCategoryChange}
                    title="Batch Orders"
                    disableButtons={isBtnDisable}
                    paginationComponent={
                        <Pagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                        />}
                />
            </Layout>
        </div>
    );
};

